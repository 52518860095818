import React from 'react';
import { Layout, Row, Col } from 'antd';
import Navbar from '../../../layouts/navbar/navbar';
import Footer from 'src/layouts/footer/footer';
import A_1 from '../../../assets/images/services/grass/a-1.jpg';
import B_1 from '../../../assets/images/services/grass/b-1.jpg';
import A_2 from '../../../assets/images/services/grass/a-2.jpg';
import B_2 from '../../../assets/images/services/grass/b-2.jpg';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';
import '.././services.css';

const ServicesPageLAWN: React.FC = () => {
    const B_1_IMAGE = { imageUrl: B_1 };
    const A_1_IMAGE = { imageUrl: A_1 };
    const B_2_IMAGE = { imageUrl: B_2 };
    const A_2_IMAGE = { imageUrl: A_2 };

    return (
        <Layout className='web-layout'>
            <Navbar />
            {/*********************************************************************PAGE HEADER */}
            <div className='wep-page-header'>
                <h1>Sky and Gress Replacement</h1>
            </div>
            {/*********************************************************************PAGE */}
            <div className='web-page-container'>
                <Row gutter={[12, 12]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <div className='web-box'>
                            <div className='serpg-box'>
                                <p className='serpg-sky-p'>If you are a real estate seller then which kind of photos would you like to show to your client or buyer. Would you like to show ‘without sky or grass’ or ‘with sky and grass’? As we believe your answer would be ‘with sky and grass.’-</p>
                                <p className='serpg-sky-p'>Sometimes due to bad weather conditions, dull or cloudy sky, lack of maintenance, negligence, changing season etc, the grass dies. Hence, in that case you may need this service to make your picture presentable.</p>
                                <p className='serpg-sky-p'>Should You Photoshop The Grass In Real Estate Photo? (this is heading big font) There is no black and white answer. It is generally not standard practice in real estate photography to modify permanent features like power lines, antennas or power poles and physical property features. But when it comes to modify impermanent or semi-permanent features like landscape likewise grass, bushes etc., there is a variety of opinions. Some will argue that grass and bushes are seasonal and it’s not a big deal to Photoshop them and some will vehemently take the other side of the argument.</p>
                                <p className='serpg-sky-p'>We can make your pictures look more attractive and striking. If your photographs have dull or washed out skies and dead grasses, we can correct them and replace them with a clear and bright sky and grass. Outsource sky change and grass replacement services to picxsquare.com real estate photo editing services and get access to professional and expert sky and grass change services at a cost-effective price.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            {/*********************************************************************SAMPLES */}
            <div className='web-page-container'>
                <Row gutter={[12, 12]} className='serpg-ba'>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                        <div className='web-box'>
                            <ReactBeforeSliderComponent firstImage={B_1_IMAGE} secondImage={A_1_IMAGE} />
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                        <div className='web-box'>
                            <ReactBeforeSliderComponent firstImage={B_2_IMAGE} secondImage={A_2_IMAGE} />
                        </div>
                    </Col>
                </Row>
            </div>
            {/*********************************************************************FOOTER */}
            <Footer />
        </Layout>
    )
}

export default ServicesPageLAWN;
export const insertContactUs = `mutation InsertContactUs($email: String, $mobile: String, $name: String, $message: String, $created_by: uuid, $updated_by: uuid) {
    insert_contact_request(objects: {created_by: $created_by, email: $email, message: $message, mobile: $mobile, name: $name, updated_by: $updated_by}) {
      affected_rows
    }
  }`;

export const insertJobRequest = `mutation InsertJobRequest($email: String, $last_name: String, $mobile: String, $first_name: String, $experience: numeric, $designation: String, $message: String, $created_by: uuid, $qualification: String, $updated_by: uuid) {
    insert_job_request(objects: {created_by: $created_by, designation: $designation, email: $email, experience: $experience, first_name: $first_name, last_name: $last_name, message: $message, mobile: $mobile, qualification: $qualification, updated_by: $updated_by}) {
      affected_rows
    }
  }`;
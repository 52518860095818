import React from 'react';
import { Layout, Row, Col } from 'antd';
import Navbar from '../../../layouts/navbar/navbar';
import Footer from 'src/layouts/footer/footer';
import A_1 from '../../../assets/images/services/deculter/a-1.jpg';
import B_1 from '../../../assets/images/services/deculter/b-1.jpg';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';
import '.././services.css';

const ServicesPageDECULTER: React.FC = () => {
    const B_1_IMAGE = { imageUrl: B_1 };
    const A_1_IMAGE = { imageUrl: A_1 };

    return (
        <Layout className='web-layout'>
            <Navbar />
            {/*********************************************************************PAGE HEADER */}
            <div className='wep-page-header'>
                <h1>Decultter</h1>
            </div>
            {/*********************************************************************PAGE */}
            <div className='web-page-container'>
                <Row gutter={[12, 12]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <div className='web-box'>
                            <div className='serpg-box'>
                                <p className='serpg-sky-p'>Our experienced and professional digital de-cluttering editors can remove, general clutter, Rubbish, pool debris, vehicles, remove signs posters, change water color, wall pictures and everything you want to take away from the image. We have a team of highly accomplished photo editors who can handle large range of images effectively, and deliver terrific results. The results provided by us are of stunning quality and clarity.</p>
                                <p className='serpg-sky-p'>From the images of your bedroom, living room, kitchen, bathroom, garden to office space, we can help you de-clutter the photographs at cost-effective prices and in quick turnaround time.
                                    We deliver a comprehensive range of real estate digital decluttering services to help realtors, real estate agents, property dealers and builders in cleaning cluttered houses, untidy vacation rentals and messy gardens.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            {/*********************************************************************SAMPLES */}
            <div className='web-page-container'>
                <Row gutter={[12, 12]} className='serpg-ba'>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <div className='web-box'>
                            <ReactBeforeSliderComponent firstImage={B_1_IMAGE} secondImage={A_1_IMAGE} />
                        </div>
                    </Col>
                    {/*<Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                        <div className='web-box'>
                            <ReactBeforeSliderComponent firstImage={B_1_IMAGE} secondImage={A_1_IMAGE} />
                        </div>
                    </Col>*/}
                </Row>
            </div>
            {/*********************************************************************FOOTER */}
            <Footer />
        </Layout>
    )
}

export default ServicesPageDECULTER;
import React from 'react';
import { Layout, Row, Col } from 'antd';
import Navbar from '../../../layouts/navbar/navbar';
import Footer from 'src/layouts/footer/footer';
import A_3 from '../../../assets/images/services/day/a-3.jpg';
import B_3 from '../../../assets/images/services/day/b-3.jpg';
import A_2 from '../../../assets/images/services/day/a-2.jpg';
import B_2 from '../../../assets/images/services/day/b-2.jpg';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';
import '.././services.css';

const ServicesPageDAY: React.FC = () => {
    const B_3_IMAGE = { imageUrl: B_3 };
    const A_3_IMAGE = { imageUrl: A_3 };
    const B_2_IMAGE = { imageUrl: B_2 };
    const A_2_IMAGE = { imageUrl: A_2 };

    return (
        <Layout className='web-layout'>
            <Navbar />
            {/*********************************************************************PAGE HEADER */}
            <div className='wep-page-header'>
                <h1>Day to Dusk</h1>
            </div>
            {/*********************************************************************PAGE */}
            <div className='web-page-container'>
                <Row gutter={[12, 12]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <div className='web-box'>
                            <div className='serpg-box'>
                                <p className='serpg-sky-p'>Want the Twilight look without being there for Twilight? Image Edits has various styles and price points to convert daytime images into twilight/nighttime images to grab the attention of potential buyers for the property.</p>
                                <p className='serpg-sky-p'>The soft glowing light from the sky when the sun is below the horizon gives a perfect setting to capture images of your property. But Day-to-Dusk photography is difficult and not exactly the same as normal real estate photography.
                                    Do not worry, just send your photos and our team of professionals will transform it from day to ideal dusk image using advance techniques.</p>
                                <p className='serpg-sky-p'>We incorporate a variety of techniques such as correcting color cast, removing unfavorable weather conditions, maintaining color balance,
                                    replacing the sky, controlling brightness and contrast, HDR processing, and more to preserve the quality and produce visually appealing photographs. Besides, we can also use artificial
                                    lighting to enhance the building's interiors and exteriors. Overall, our day-to-dusk conversion services for real estate images can surely help your listings to stand out from the crowd and
                                    attract more prospects. Refer to our recent work sample to know more.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            {/*********************************************************************SAMPLES */}
            <div className='web-page-container'>
                <Row gutter={[12, 12]} className='serpg-ba'>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                        <div className='web-box'>
                            <ReactBeforeSliderComponent firstImage={B_3_IMAGE} secondImage={A_3_IMAGE} />
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                        <div className='web-box'>
                            <ReactBeforeSliderComponent firstImage={B_2_IMAGE} secondImage={A_2_IMAGE} />
                        </div>
                    </Col>
                </Row>
            </div>
            {/*********************************************************************FOOTER */}
            <Footer />
        </Layout>
    )
}

export default ServicesPageDAY;
import React, { useState } from 'react';
import { Layout, Row, Col, Form, Button, Input, Alert } from 'antd';
import { PhoneOutlined, MailOutlined, FacebookOutlined, InstagramOutlined, SkypeOutlined, EnvironmentOutlined, WhatsAppOutlined } from '@ant-design/icons';
import Navbar from 'src/layouts/navbar/navbar';
import Footer from 'src/layouts/footer/footer';
import { useStore } from "../../use-stores";
import { SERVER_BOT } from "../../utils/constant";
import { Link } from 'react-router-dom';
import './contact.css';

const ContactPage: React.FC = () => {
    const { generalStore } = useStore();
    const [basicFormState, setBasicFormState] = useState<boolean>(false);
    const [alertSuccess, setAlertSuccess] = useState<boolean>(false);
    const [alertFailed, setAlertFailed] = useState<boolean>(false);
    const [form] = Form.useForm();

    const letsAddContact = async (value: any) => {
        try {
            setBasicFormState(true);
            let _post: any = {
                name: value.name,
                email: value.email,
                mobile: value.mobile,
                message: value.message,
                created_by: SERVER_BOT,
                updated_by: SERVER_BOT
            }
            await generalStore.doCantactUs(_post).then((_d: any) => {
                if (_d && _d.status === "error") {
                    setAlertFailed(true); setAlertSuccess(false); form.resetFields();
                } else { setAlertSuccess(true); setAlertFailed(false); form.resetFields(); }
            }).finally(() => {
                setBasicFormState(false)
            })
        } catch (e) {
            console.error(e)
        }
    }
    const onFailure = (errorInfo: any) => {
        console.error(errorInfo)
    }

    return (
        <Layout className='web-layout'>
            <Navbar />
            {/*********************************************************************PAGE HEADER */}
            <div className='wep-page-header'>
                <h1>Contact Us__</h1>
            </div>
            {/*********************************************************************PAGE */}
            <div className='web-page-container'>
                <Row gutter={[12, 12]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                        <div className='web-box'>
                            <h2 className='header-h2'>Let's touch with us </h2>
                            {alertSuccess && <Alert
                                message="Success! Picxsquare person will contact you within 24hrs. Thank you."
                                type="success"
                                showIcon style={{ marginTop: "15px" }}
                            />}
                            {alertFailed && <Alert
                                message="Failed! Something went wrong. Try again."
                                type="error"
                                showIcon style={{ marginTop: "15px" }}
                            />}
                            <Form form={form} onFinish={letsAddContact} disabled={basicFormState} onFinishFailed={onFailure} className='web-inner-box' layout="vertical" autoComplete="off">
                                <Form.Item name="name" label={<label className="web-form-label">Full Name</label>} rules={[{ required: true, message: "Please input your Full Name" }]}>
                                    <Input className="web-form-input" />
                                </Form.Item>
                                <Form.Item name="mobile" label={<label className="web-form-label">Mobile</label>} rules={[{ required: true, message: "Please input your mobile number" }]}>
                                    <Input className="web-form-input" />
                                </Form.Item>
                                <Form.Item name="email" label={<label className="web-form-label">Email</label>} rules={[{ required: true, type: "email", message: "Please input your Email" }]}>
                                    <Input className="web-form-input" />
                                </Form.Item>
                                <Form.Item name="message" label={<label className="web-form-label">Message</label>} rules={[{ required: true, message: "Please input your Message" }]}>
                                    <Input.TextArea className="web-form-textarea" rows={4} />
                                </Form.Item>
                                <Button htmlType="submit" size="large" className="web-primary-btn">Send</Button>
                            </Form>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                        <div className='web-box'>
                            <h2 className='header-h2'>Contact Info</h2>
                            <div className='web-inner-box'>
                                <ul className='contpg-list'>
                                    <li><EnvironmentOutlined className='pd-rit-1' /> 21, Anna Nagar, Chennai - 600040, Tamil Nadu, India</li>
                                    <li><PhoneOutlined className='pd-rit-1' /> +91 80980 93440</li>
                                    <li><MailOutlined className='pd-rit-1' /> picxsquare@gmail.com</li>
                                    <li>
                                        <div>
                                            <a href='https://www.facebook.com/profile.php?id=100088562838081&mibextid=ZbWKwL' target={'_blank'}><span className='contpg-social-ico'><FacebookOutlined /></span></a>
                                            <a href='https://www.instagram.com/picxsquarestudio/' target={'_blank'}><span className='contpg-social-ico'><InstagramOutlined /></span></a>
                                            {/*<Link to='/services'><span className='contpg-social-ico'><SkypeOutlined /></span></Link>*/}
                                            <a href='https://wa.me/+918098093440' target={'_blank'}><span className='contpg-social-ico'><WhatsAppOutlined /></span></a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            {/*********************************************************************FOOTER */}
            <Footer />
        </Layout>
    )
}

export default ContactPage;
import { useState } from 'react';
import { AlignRightOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Layout, MenuProps, Dropdown } from "antd";
import Logo from "src/assets/images/logo-black.png";
import { useNavigate } from 'react-router-dom';
import "./navbar.css";

const { Header } = Layout;

function Navbar() {
    const [isNavOpen, setIsNavOpen] = useState<boolean>(true);
    const navigate = useNavigate();

    const showMenu = () => {
        setIsNavOpen(!isNavOpen);
    }
    const goToScreens = (screen: any) => {
        navigate(screen);
    }

    const items: MenuProps['items'] = [
        { key: 'day_to_dusk', label: (<a rel="noopener noreferrer" href="/services-1">Day to Dusk</a>) },
        { key: 'flash_ambient', label: (<a rel="noopener noreferrer" href="/services-2">Flash Ambient</a>) },
        { key: 'hdr_blending', label: (<a rel="noopener noreferrer" href="/services-3">HDR Blending</a>) },
        { key: 'sky_replace', label: (<a rel="noopener noreferrer" href="/services-4">Sky Replacement</a>) },
        { key: 'lawn_replacement', label: (<a rel="noopener noreferrer" href="/services-5">Lawn Replacement</a>) },
        { key: '360_panorama', label: (<a rel="noopener noreferrer" href="/services-6">360 Panorama</a>) },
        { key: 'decultter', label: (<a rel="noopener noreferrer" href="/services-7">Decultter</a>) },
        { key: 'virtual_staging', label: (<a rel="noopener noreferrer" href="/services-8">Virtual Staging</a>) },
        { key: 'virtual_twilight', label: (<a rel="noopener noreferrer" href="/services-9">Virtual Twilight</a>) }
    ];

    return (
        <>
            <Header className="web-navbar">
                <div className="web-navbar-header-logo">
                    <Button className="web-navbar-logo-btn"><img src={Logo} alt="logo" className="web-navbar-logo" /></Button>
                </div>
                <div className="web-navbar-home-bars" onClick={showMenu}>
                    <AlignRightOutlined />
                </div>
                <div className="web-navbar-header-menu">
                    <div className="web-navbar-header-menu-link web-navbar-link-active" onClick={() => goToScreens('/home')}>Home</div>
                    <div className="web-navbar-header-menu-link web-navbar-link-active" onClick={() => goToScreens('/home#about')}>About</div>
                    <Dropdown menu={{ items }} placement="bottomLeft">
                        <div className="web-navbar-header-menu-link web-navbar-link-active">Services</div>
                    </Dropdown>
                    <div className="web-navbar-header-menu-link web-navbar-link-active" onClick={() => goToScreens('/careers')}>Careers</div>
                    <div className="web-navbar-header-menu-link web-navbar-link-active" onClick={() => goToScreens('/gallery')}>Gallery</div>
                    <div className="web-navbar-header-menu-link web-navbar-link-active" onClick={() => goToScreens('/contact')}>Contact Us</div>
                </div>
                {!isNavOpen && (
                    <div className="web-navbar-responsive-navbar">
                        <div className="web-navbar-close-sidebar" onClick={showMenu}>
                            <CloseCircleOutlined />
                        </div>
                        <div className="web-navbar-responsive-menu">
                            <div className="web-navbar-responsive-menu-link web-navbar-link-active" onClick={() => goToScreens('/home')}>Home</div>
                            <div className="web-navbar-responsive-menu-link web-navbar-link-active" onClick={() => goToScreens('/home#about')}>About</div>
                            <Dropdown menu={{ items }} placement="bottomLeft">
                                <div className="web-navbar-responsive-menu-link web-navbar-link-active">Services</div>
                            </Dropdown>
                            <div className="web-navbar-responsive-menu-link web-navbar-link-active" onClick={() => goToScreens('/careers')}>Careers</div>
                            <div className="web-navbar-responsive-menu-link web-navbar-link-active" onClick={() => goToScreens('/gallery')}>Gallery</div>
                            <div className="web-navbar-responsive-menu-link" onClick={() => goToScreens('/contact')}>Contact us</div>
                        </div>
                    </div>
                )}
            </Header>
        </>
    );
}

export default Navbar;

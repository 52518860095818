import React, { useEffect } from 'react';
import { Row, Col, Button } from 'antd';
import RightSideImg from '../../assets/images/landing-illu.png';
import Logo from '../../assets/images/logo-black.png';
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import './landing.css';

const LandingPage: React.FC = () => {
    useEffect(() => {
        const prevTitle = "Picxsquare | Welcome";
        document.title = "Picxsquare | Welcome";
        return () => {
          document.title = prevTitle;
        };
    });

    const navigate = useNavigate();

    return (
        <div className='landingpg'>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <img src={Logo} alt="Piaxsquare-Logo" className='landingpg-logo' />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="txt-align-end">
                    <div className='landingpg-menu'>
                        <Button onClick={() => navigate("/home")} className='landingpg-menu-btn'>Home</Button>
                    </div>
                    <div className='landingpg-menu'>
                        <Button onClick={() => navigate("/contact")} className='landingpg-menu-btn'>Contact</Button>
                    </div>
                </Col>
            </Row>
            <Row align={'middle'}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} className='landingpg-cont-col'>
                    <div className='landingpg-box'>
                        <h1 className='landingpg-header'>Picxsquare! <br />Get The Best real estate photo Services</h1>
                        <p className='landingpg-content'>We edit and provide best real estate photoes to make your project goals and deadlines a successful one with unbeatable price.</p>
                        <Button onClick={() => navigate("/home")} className='landingpg-start-btn'>Lets Start</Button>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} className='landingpg-rightside-col'>
                    <img src={RightSideImg} alt='landing-img' className='landingpg-rightsideimg' />
                </Col>
            </Row>
        </div>
    )
}

export default LandingPage;
import axios, { AxiosRequestHeaders } from "axios";
import { makeObservable } from "mobx";
import { TokenStore } from "./tokenstore";
import { GRAPHQL_URL } from "../utils/constant";
import { isNotEmpty } from "../utils";
import { insertContactUs, insertJobRequest } from "../utils/queries";

const _ErrorResponse = (_m: any = null, _d: any = null) => {
    return { status: 'error', message: typeof _m === 'string' && _m ? _m : 'Something went wrong, Please try again after sometime.', data: _d }
};
const _SuccessResponse = (_m: any = null, _d: any = null) => {
    return { status: 'success', message: _m && typeof _m === 'string' ? _m : 'Process Successfully Done', data: _d }
};

export class GeneralStore {
    //pincodeAvailability: any = []

    constructor(private tokenStore: TokenStore) {
        makeObservable(this, {
            /*pincodeAvailability: observable,
            setPincodeAvailability: action*/
        });
    }
    /***********************************************************************FUNCTIONS */
    private getHeaders(noToken?: boolean): AxiosRequestHeaders {
        if (noToken) {
            return { "x-hasura-role": "anonymous", "x-client-app": "picxsquare-web" };
        } else if (this.tokenStore.validToken()) {
            return { authorization: `Bearer ${this.tokenStore.getToken()}` };
        }
        return { "x-hasura-role": "anonymous", "x-client-app": "picxsquare-web" };
    }

    public async doCantactUs(_post: any) {
        const _self = this;
        try {
            let res = await axios.post(GRAPHQL_URL, { query: insertContactUs, variables: _post }, { headers: _self.getHeaders() })
            if (res && res.data && isNotEmpty(res.data.errors)) {
                return _ErrorResponse(res.data.errors[0].message || null)
            }
            if (res && res.data) {
                return _SuccessResponse(null, res.data.data)
            } else return _ErrorResponse()
        } catch (e) {
            console.error(e)
            return _ErrorResponse(e)
        }
    }

    public async doJobRequest(_post: any) {
        const _self = this;
        try {
            let res = await axios.post(GRAPHQL_URL, { query: insertJobRequest, variables: _post }, { headers: _self.getHeaders() })
            if (res && res.data && isNotEmpty(res.data.errors)) {
                return _ErrorResponse(res.data.errors[0].message || null)
            }
            if (res && res.data) {
                return _SuccessResponse(null, res.data.data)
            } else return _ErrorResponse()
        } catch (e) {
            console.error(e)
            return _ErrorResponse(e)
        }
    }

    /*public async getPincodeAvailability(_post: any) {
        const _self = this;
        try {
            let res = await axios.post(`${GRAPHQL_URL}/api/web/getavailablity`, _post, { headers: _self.getHeaders(true) })
            if (res && res.data) {
                if (res.data.status && res.data.status === 'failed') {
                    _self.setPincodeAvailability([]);
                    return _ErrorResponse(res.data.message || 'Something went wrong, Please try again after sometime.')
                } else {
                    _self.setPincodeAvailability(res.data.data);
                    return _SuccessResponse(null, res.data.data)
                }
            } else return _ErrorResponse()
        } catch (e) {
            console.error(e)
            return _ErrorResponse(e)
        }
    }*/

    /***********************************************************************ACTIONS */
    /*setPincodeAvailability(v: any) {
        this.pincodeAvailability = v;
    }*/
}

import tokenStore, { TokenStore } from "./tokenstore";
import { GeneralStore } from "./generalstore";

export type RootStore = {
    tokenStore: TokenStore,
    generalStore: GeneralStore
}

const rootStore = {
    tokenStore,
    generalStore: new GeneralStore(tokenStore)
};

export default rootStore;
import React from 'react';
import { Layout, Row, Col } from 'antd';
import Navbar from '../../../layouts/navbar/navbar';
import Footer from 'src/layouts/footer/footer';
import A_1 from '../../../assets/images/services/hdr/a-1.jpg';
import B_1 from '../../../assets/images/services/hdr/b-1.jpg';
import A_2 from '../../../assets/images/services/hdr/a-2.jpg';
import B_2 from '../../../assets/images/services/hdr/b-2.jpg';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';
import '.././services.css';

const ServicesPageHDR: React.FC = () => {
    const B_1_IMAGE = { imageUrl: B_1 };
    const A_1_IMAGE = { imageUrl: A_1 };
    const B_2_IMAGE = { imageUrl: B_2 };
    const A_2_IMAGE = { imageUrl: A_2 };

    return (
        <Layout className='web-layout'>
            <Navbar />
            {/*********************************************************************PAGE HEADER */}
            <div className='wep-page-header'>
                <h1>HDR Blending</h1>
            </div>
            {/*********************************************************************PAGE */}
            <div className='web-page-container'>
                <Row gutter={[12, 12]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <div className='web-box'>
                            <div className='serpg-box'>
                                <p className='serpg-sky-p'>The process of High Dynamic Range Blending or HDR image editing involves blending of two or more images that vary in temperature, quality, and brightness and creating unimpaired images. This service is very useful for Professional Photographers who want to remove the image flaws that might occur during the shoot based on the lighting and temparature.</p>
                                <p className='serpg-sky-p'>HDR Blending Service provided by Images retouch Includes the following:</p>
                                <ul className='serpg-list'>
                                    <li>Retaining Window details</li>
                                    <li>Blending Multi Exposure Adjustments</li>
                                    <li>White Balance Adjustments</li>
                                    <li>Highlights and Shadows</li>
                                    <li>Outlook Corrections and Noise Reductions</li>
                                    <li>Sky replacement or removal flashlights</li>
                                    <li>unwanted object removal</li>
                                    <li>Fire in Fire Place</li>
                                    <li>TV screen replacement, etc</li>
                                </ul>
                                <p className='serpg-sky-p'>Over the years, Images Retouch has gained the fame of being a trusted source for the service. Many of our customers in the field of Real Estate Photgraphy & Listing Agents have a long term participation with us. To gain your trust, we simply offer a sample (trial) job without cost or commitment to give cognizance into our Real Estate HDR Photo Editing Services.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            {/*********************************************************************SAMPLES */}
            <div className='web-page-container'>
                <Row gutter={[12, 12]} className='serpg-ba'>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                        <div className='web-box'>
                            <ReactBeforeSliderComponent firstImage={B_1_IMAGE} secondImage={A_1_IMAGE} />
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                        <div className='web-box'>
                            <ReactBeforeSliderComponent firstImage={B_2_IMAGE} secondImage={A_2_IMAGE} />
                        </div>
                    </Col>
                </Row>
            </div>
            {/*********************************************************************FOOTER */}
            <Footer />
        </Layout>
    )
}

export default ServicesPageHDR;
import React from 'react';
import { Row, Col, Anchor } from 'antd';
import { PhoneOutlined, MailOutlined, FacebookOutlined, InstagramOutlined, SkypeOutlined, WhatsAppOutlined } from '@ant-design/icons';
import LogoWhite from '../../assets/images/logo-gold.png';
import Top from '../../assets/images/icons/top.png';
import { Link } from 'react-router-dom';
import './footer.css';

const Footer: React.FC = () => {
    const { Link: AnchorLink } = Anchor;
    return (
        <div className='web-footer'>
            <Row gutter={[16, 16]} className='web-footer-main'>
                <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                    <div className='web-footer-cols'>
                        <h1><u>Useful links</u></h1>
                        <ul className='web-footer-list'>
                            <li><Link to='/home'>Home</Link></li>
                            <li><a href="/home#about">About</a></li>
                            <li><a href="/home#hiw">How it Works</a></li>
                            <li><Link to='/careers'>Careers</Link></li>
                            <li><Link to='/gallery'>Gallery</Link></li>
                            <li><Link to='/contact'>Contact</Link></li>
                            <li><Link to='/termsandconditions'>Terms & Conditions</Link></li>
                            <li><Link to='/privacypolicy'>Privacy Policy</Link></li>
                        </ul>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                    <div className='web-footer-cols'>
                        <h1><u>Services</u></h1>
                        <ul className='web-footer-list'>
                            <li><Link to='/services-1'>Day to Dusk</Link></li>
                            <li><Link to='/services-2'>Flash Ambient</Link></li>
                            <li><Link to='/services-3'>HDR Blending</Link></li>
                            <li><Link to='/services-4'>Sky Replacement</Link></li>
                            <li><Link to='/services-5'>Lawn Replacement</Link></li>
                            <li><Link to='/services-6'>360 Panorama</Link></li>
                            <li><Link to='/services-7'>Deculter</Link></li>
                            <li><Link to='/services-8'>Virtual Staging</Link></li>
                            <li><Link to='/services-9'>Virtual Twilight</Link></li>
                        </ul>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                    <div className='web-footer-cols'>
                        <h1><u>Contact</u></h1>
                        <ul className='web-footer-list'>
                            <li>
                                <div className='dis-flex' style={{ alignItems: 'center' }}>
                                    <img src={LogoWhite} alt="Picxsquare" className='web-footer-logo' />
                                    <span><h1 className='web-footer-logoname'>Picxsquare</h1></span>
                                </div>
                            </li>
                            <li><PhoneOutlined className='pd-rit-1' /> +91 80980 93440</li>
                            <li><MailOutlined className='pd-rit-1' /> picxsquare@gmail.com</li>
                            <li>
                                <div>
                                    <a href='https://www.facebook.com/profile.php?id=100088562838081&mibextid=ZbWKwL' target={'_blank'}><span className='web-footer-contact-ico'><FacebookOutlined /></span></a>
                                    <a href='https://www.instagram.com/picxsquarestudio/' target={'_blank'}><span className='web-footer-contact-ico'><InstagramOutlined /></span></a>
                                    {/*<Link to='/services' target={'_blank'}><span className='web-footer-contact-ico'><SkypeOutlined /></span></Link>*/}
                                    <a href='https://wa.me/+918098093440' target={'_blank'}><span className='web-footer-contact-ico'><WhatsAppOutlined /></span></a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </Col>
            </Row>
            <Row className='web-footer-cpywrt'>
                <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                    <p className='web-footer-cpywrt-txt'>© Picxsquare Info Tech. 2022</p>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className='web-footer-anchor-col'>
                    <AnchorLink href="#top" title={<img src={Top} style={{ width: '64px' }} alt="Picxsquare" />} className='web-footer-anchor' />
                </Col>
            </Row>
        </div>
    )
}

export default Footer;
import React from 'react';
import { Layout, Row, Col } from 'antd';
import Navbar from '../../../layouts/navbar/navbar';
import Footer from 'src/layouts/footer/footer';
import A_1 from '../../../assets/images/services/panorama/a-1.jpg';
import B_1 from '../../../assets/images/services/panorama/b-1.jpg';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';
import '.././services.css';

const ServicesPagePANORAMA: React.FC = () => {
    const B_1_IMAGE = { imageUrl: B_1 };
    const A_1_IMAGE = { imageUrl: A_1 };

    return (
        <Layout className='web-layout'>
            <Navbar />
            {/*********************************************************************PAGE HEADER */}
            <div className='wep-page-header'>
                <h1>360 Panorama</h1>
            </div>
            {/*********************************************************************PAGE */}
            <div className='web-page-container'>
                <Row gutter={[12, 12]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <div className='web-box'>
                            <div className='serpg-box'>
                                <p className='serpg-sky-p'>Dynamics are more powerful to draw a viewer’s attention than the statics. Panoramic view of an object encourages a viewer to become live with it, taking him or her as an integral part and definitely a significant player of the dynamic frame. Sometimes, it can create an optical illusion to a viewer in a spatiotemporal dimension. A professionally created 360 Degree Panorama Photo Stitching can definitely prove “life is greater than art”.</p>
                                <p className='serpg-sky-p'>A 360° panorama creation can eclipse any static artwork by a certain richness, liveliness and complexity. It awakens a widely different sentiment far-reaching of the monotony created by a piece of static art. Therefore, 360 Degree Panorama Photo Stitching is extensively used in almost all niche and industries for accentuating a product and services for selling and marketing these easily. Panoramic views are highly effective for sales promotion, advertising and digital marketing.</p>
                                <p className='serpg-sky-p'>Having numerous benefits of 360 Degree Panorama Photo Stitching in accentuating a business, its products and services significantly, we, at Ours Picxsquare Photo Editing Service Company, are dedicated to ensuring you all through our professional photo stitching services. Our expert solutions on it allow the potential customers to rotate a picture and examine it properly after having a quick view of it from the different sides.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            {/*********************************************************************SAMPLES */}
            <div className='web-page-container'>
                <Row gutter={[12, 12]} className='serpg-ba'>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <div className='web-box'>
                            <ReactBeforeSliderComponent firstImage={B_1_IMAGE} secondImage={A_1_IMAGE} />
                        </div>
                    </Col>
                    {/*<Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                        <div className='web-box'>
                            <ReactBeforeSliderComponent firstImage={B_1_IMAGE} secondImage={A_1_IMAGE} />
                        </div>
                    </Col>*/}
                </Row>
            </div>
            {/*********************************************************************FOOTER */}
            <Footer />
        </Layout>
    )
}

export default ServicesPagePANORAMA;
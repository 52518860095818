import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from './screens/landing/landing';
import HomePage from "./screens/home/home";
import ContactPage from "./screens/contact/contact";
import ServicesPageSKY from "./screens/services/screens/services_sky";
import ServicesPageDAY from "./screens/services/screens/services_day";
import ServicesPageFLASH from "./screens/services/screens/services_flash";
import ServicesPageHDR from "./screens/services/screens/services_hdr";
import ServicesPageLAWN from "./screens/services/screens/services_lawn";
import ServicesPagePANORAMA from "./screens/services/screens/services_panorama";
import ServicesPageDECULTER from "./screens/services/screens/services_deculter";
import ServicesPageSTAGING from "./screens/services/screens/services_staging";
import ServicesPageTWLIGHT from "./screens/services/screens/services_twilight";
import GalleryPage from "./screens/gallery/gallery";
import CareersPage from "./screens/careers/careers";
import TermsPage from "./screens/termsandconditions/termsandconditions";
import PrivacyPage from "./screens/privacy/privacy";
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/services-1" element={<ServicesPageDAY />} />
          <Route path="/services-2" element={<ServicesPageFLASH />} />
          <Route path="/services-3" element={<ServicesPageHDR />} />
          <Route path="/services-4" element={<ServicesPageSKY />} />
          <Route path="/services-5" element={<ServicesPageLAWN />} />
          <Route path="/services-6" element={<ServicesPagePANORAMA />} />
          <Route path="/services-7" element={<ServicesPageDECULTER />} />
          <Route path="/services-8" element={<ServicesPageSTAGING />} />
          <Route path="/services-9" element={<ServicesPageTWLIGHT />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/careers" element={<CareersPage />} />
          <Route path="/termsandconditions" element={<TermsPage />} />
          <Route path="/privacypolicy" element={<PrivacyPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

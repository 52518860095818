import React from 'react';
import { Layout, Row, Col } from 'antd';
import Navbar from '../../../layouts/navbar/navbar';
import Footer from 'src/layouts/footer/footer';
import A_1 from '../../../assets/images/services/twlight/a-1.jpg';
import B_1 from '../../../assets/images/services/twlight/b-1.jpg';
import A_2 from '../../../assets/images/services/twlight/a-2.jpg';
import B_2 from '../../../assets/images/services/twlight/b-2.jpg';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';
import '.././services.css';

const ServicesPageTWLIGHT: React.FC = () => {
    const B_1_IMAGE = { imageUrl: B_1 };
    const A_1_IMAGE = { imageUrl: A_1 };
    const B_2_IMAGE = { imageUrl: B_2 };
    const A_2_IMAGE = { imageUrl: A_2 };

    return (
        <Layout className='web-layout'>
            <Navbar />
            {/*********************************************************************PAGE HEADER */}
            <div className='wep-page-header'>
                <h1>Virtual Twilight</h1>
            </div>
            {/*********************************************************************PAGE */}
            <div className='web-page-container'>
                <Row gutter={[12, 12]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <div className='web-box'>
                            <div className='serpg-box'>
                                <p className='serpg-sky-p'>Virtual Twilights give the illusion of beautiful Twilight photography without the hassle of scheduling a separate evening appointment. If the weather isn’t cooperating for a Twilight shoot or you only need a few photos featuring a stunning sunset in the background, this service is a great way to easily transform existing daytime photography into a Virtual Twilight.</p>
                                <p className='serpg-sky-p'>The warmth and welcoming appeal of twilight photos really set off a glamorous, stylish vibe attracting more views online, and ultimately creating an emotional connection for buyers. Check out these before/afters and you’ll see just how alluring twilight can truly be!</p>
                                <p className='serpg-sky-p'>We edit the photos property like we normally would, then our talented group of 3D CGI artists use the latest and greatest Hollywood techniques and software to magically make your afternoon shot look like dusk!</p>
                                <p className='serpg-sky-p'>Virtual Twilight is a great alternative to a full twilight shoot. Get that one “Magic Hour” photo you need to tell a home’s story.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            {/*********************************************************************SAMPLES */}
            <div className='web-page-container'>
                <Row gutter={[12, 12]} className='serpg-ba'>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                        <div className='web-box'>
                            <ReactBeforeSliderComponent firstImage={B_1_IMAGE} secondImage={A_1_IMAGE} />
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                        <div className='web-box'>
                            <ReactBeforeSliderComponent firstImage={B_2_IMAGE} secondImage={A_2_IMAGE} />
                        </div>
                    </Col>
                </Row>
            </div>
            {/*********************************************************************FOOTER */}
            <Footer />
        </Layout>
    )
}

export default ServicesPageTWLIGHT;
import React, { useState, useEffect } from 'react';
import { Layout, Carousel, Row, Col, Button, Typography, Divider } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import { useLocation } from 'react-router-dom';
import 'react-before-after-slider-component/dist/build.css';
import Navbar from 'src/layouts/navbar/navbar';
import Footer from 'src/layouts/footer/footer';
import Banner_1 from '../../assets/images/banner/1.jpg';
import Banner_2 from '../../assets/images/banner/2.jpg';
import Banner_3 from '../../assets/images/banner/3.jpg';
import Banner_4 from '../../assets/images/banner/4.jpg';
import Banner_5 from '../../assets/images/banner/5.jpg';
import Banner_6 from '../../assets/images/banner/6.jpg';
import Banner_7 from '../../assets/images/banner/7.jpg';
import A_1 from '../../assets/images/before-after/a-1.jpg';
import A_2 from '../../assets/images/before-after/a-2.jpg';
import A_3 from '../../assets/images/before-after/a-3.jpg';
import A_4 from '../../assets/images/before-after/a-4.jpg';
import A_5 from '../../assets/images/before-after/a-5.jpg';
import A_6 from '../../assets/images/before-after/a-6.jpg';
import A_7 from '../../assets/images/before-after/a-7.jpg';
import B_1 from '../../assets/images/before-after/b-1.jpg';
import B_2 from '../../assets/images/before-after/b-2.jpg';
import B_3 from '../../assets/images/before-after/b-3.jpg';
import B_4 from '../../assets/images/before-after/b-4.jpg';
import B_5 from '../../assets/images/before-after/b-5.jpg';
import B_6 from '../../assets/images/before-after/b-6.jpg';
import B_7 from '../../assets/images/before-after/b-7.jpg';
import Features_1 from '../../assets/images/icons/feature_1.png';
import Features_2 from '../../assets/images/icons/feature_2.png';
import Features_3 from '../../assets/images/icons/feature_3.png';
import Features_4 from '../../assets/images/icons/feature_4.png';
import Features_5 from '../../assets/images/icons/feature_5.png';
import Features_6 from '../../assets/images/icons/feature_6.png';
import Work_1 from '../../assets/images/icons/work_1.png';
import Work_2 from '../../assets/images/icons/work_2.png';
import Work_3 from '../../assets/images/icons/work_3.png';
import Work_4 from '../../assets/images/icons/work_4.png';
import { useNavigate } from "react-router-dom";
import './home.css';

const HomePage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (location.hash) {
            setTimeout(() => {
                document.getElementById(location.hash.replace('#', ''))!.scrollIntoView({
                    behavior: 'smooth',
                });
            }, 500);
        }
    })

    const [slide, setSlide] = useState<any>(0);
    const B_1_IMAGE = { imageUrl: B_1 };
    const A_1_IMAGE = { imageUrl: A_1 };
    const B_2_IMAGE = { imageUrl: B_2 };
    const A_2_IMAGE = { imageUrl: A_2 };
    const B_3_IMAGE = { imageUrl: B_3 };
    const A_3_IMAGE = { imageUrl: A_3 };
    const B_4_IMAGE = { imageUrl: B_4 };
    const A_4_IMAGE = { imageUrl: A_4 };
    const B_5_IMAGE = { imageUrl: B_5 };
    const A_5_IMAGE = { imageUrl: A_5 };
    const B_6_IMAGE = { imageUrl: B_6 };
    const A_6_IMAGE = { imageUrl: A_6 };
    const B_7_IMAGE = { imageUrl: B_7 };
    const A_7_IMAGE = { imageUrl: A_7 };

    return (
        <Layout className='web-layout'>
            {/***************************************************************HEADER */}
            <Navbar />
            {/***************************************************************BANNER */}
            <div id="top">
                <Carousel autoplay effect="fade">
                    <div>
                        <img src={Banner_1} className='homepg-banner-img' alt="Picxsquare_banner" />
                    </div>
                    <div>
                        <img src={Banner_2} className='homepg-banner-img' alt="Picxsquare_banner" />
                    </div>
                    <div>
                        <img src={Banner_3} className='homepg-banner-img' alt="Picxsquare_banner" />
                    </div>
                    <div>
                        <img src={Banner_4} className='homepg-banner-img' alt="Picxsquare_banner" />
                    </div>
                    <div>
                        <img src={Banner_5} className='homepg-banner-img' alt="Picxsquare_banner" />
                    </div>
                    <div>
                        <img src={Banner_6} className='homepg-banner-img' alt="Picxsquare_banner" />
                    </div>
                    <div>
                        <img src={Banner_7} className='homepg-banner-img' alt="Picxsquare_banner" />
                    </div>
                </Carousel>
            </div>
            {/***************************************************************ABOUT US */}
            <div className='web-sections-light homepg-abt-section' id="about">
                <h1 className='header-h1'>About Us</h1>
                <p className='homepg-abt-text'>
                    Welcome to Picxsquare.com. Augment your commercial/residential property values and inspire sales with top-notch real estate photo editing services from Picxsquare. We will edit your property photos in a fast and efficient way. Our services are straightway to professional property photography. Picxsquare offers a wide area of services to everyone from real estate agents to developers and online retailers who want to showcase their products with professional photos. Let us help you to create a dream home with the help of real estate retouching. We provide you unrestrained access to world-class real estate photo editors, 24-hour operations, and the latest image processing techniques within your budget.
                    <br /><br />
                    Picxsquare.com India is also renowned for its high-efficiency rate of editing bulk images with one of the highest daily editing rates in the market. Having worked with over a thousand clients for a variety of services, Picxsquare knows how to deal with tough situations – and can restore bad quality photographs, and specializes in photo stitching services.
                </p>
            </div>
            {/***************************************************************FEATURES */}
            <div className='web-sections-dark'>
                <h1 className='header-h1'>Features</h1>
                <Row className='homepg-features' gutter={[24, 24]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={4}>
                        <img src={Features_3} style={{ width: '64px' }} alt="features_icon" />
                        <h2 className='header-h2'>24 / 7 Support</h2>
                        <p className='homepg-feature-text'>We are servicing 24-hour turn around.</p>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={4}>
                        <img src={Features_6} style={{ width: '64px' }} alt="features_icon" />
                        <h2 className='header-h2'>Unlimited Changes</h2>
                        <p className='homepg-feature-text'>We are providing unlimited change for client satisfaction.</p>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={4}>
                        <img src={Features_1} style={{ width: '64px' }} alt="features_icon" />
                        <h2 className='header-h2'>Quality Test</h2>
                        <p className='homepg-feature-text'>Our team will ensure the editing is executed to the highest of the standards of the images before delivering.</p>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={4}>
                        <img src={Features_4} style={{ width: '64px' }} alt="features_icon" />
                        <h2 className='header-h2'>Free Trial</h2>
                        <p className='homepg-feature-text'>Get 5 image enhancements and 1 day to dusk edit on the first time.</p>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={4}>
                        <img src={Features_2} style={{ width: '64px' }} alt="features_icon" />
                        <h2 className='header-h2'>Customer Services</h2>
                        <p className='homepg-feature-text'>Our customer service experts are available 24/7 on Skype, phone, email and Instagram.</p>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={4}>
                        <img src={Features_5} style={{ width: '64px' }} alt="features_icon" />
                        <h2 className='header-h2'>No Subscription</h2>
                        <p className='homepg-feature-text'>You have to pay one fixed low rate per image, no subscriptions are necessary.</p>
                    </Col>
                </Row>
            </div>
            {/***************************************************************BEFORE AFTER */}
            <div className='web-sections-light homepg-ba-section'>
                <h1 className='header-h1'>Let's see our works</h1>
                <p className='homepg-ba-text'>Drag the logo below to see how our experts turn any photo into a cover shot.</p>
                <Carousel autoplay={false} ref={ref => { ref?.goTo(slide, true) }} >
                    <div >
                        <ReactBeforeSliderComponent firstImage={B_1_IMAGE} secondImage={A_1_IMAGE} />
                    </div>
                    <div>
                        <ReactBeforeSliderComponent firstImage={B_2_IMAGE} secondImage={A_2_IMAGE} />
                    </div>
                    <div>
                        <ReactBeforeSliderComponent firstImage={B_3_IMAGE} secondImage={A_3_IMAGE} />
                    </div>
                    <div>
                        <ReactBeforeSliderComponent firstImage={B_4_IMAGE} secondImage={A_4_IMAGE} />
                    </div>
                    <div>
                        <ReactBeforeSliderComponent firstImage={B_5_IMAGE} secondImage={A_5_IMAGE} />
                    </div>
                    <div>
                        <ReactBeforeSliderComponent firstImage={B_6_IMAGE} secondImage={A_6_IMAGE} />
                    </div>
                    <div>
                        <ReactBeforeSliderComponent firstImage={B_7_IMAGE} secondImage={A_7_IMAGE} />
                    </div>
                </Carousel>
                <div className='homepg-ba-icons'>
                    <Button onClick={() => { setSlide(slide + 1) }} disabled={slide > 7 ? true : false} className='homepg-nxt-pre-btn'><CaretRightOutlined /></Button>
                    <Button onClick={() => { setSlide(slide - 1) }} disabled={slide <= 0 ? true : false} className='homepg-nxt-pre-btn'><CaretLeftOutlined /></Button>
                </div>
            </div>
            {/***************************************************************SERVICES */}
            <div className='web-sections-dark'>
                <h1 className='header-h1'>Services</h1>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6} className='homepg-service-col'>
                        <div className='homepg-service-box'>
                            <h2 className='header-h2'>Day to Dusk</h2>
                            <Divider />
                            <Typography.Paragraph ellipsis={{ rows: 3 }} className='homepg-service-text'>
                                Want the Twilight look without being there for Twilight? Image Edits has various styles and price points to convert daytime images into twilight/nighttime images to grab the attention of potential buyers for the property.
                            </Typography.Paragraph>
                            <Button onClick={() => navigate("/services-1")} className='web-primary-btn'>View More</Button>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6} className='homepg-service-col'>
                        <div className='homepg-service-box'>
                            <h2 className='header-h2'>Flash Ambient</h2>
                            <Divider />
                            <Typography.Paragraph ellipsis={{ rows: 3 }} className='homepg-service-text'>
                                To support the fastest growing photography techniques of ‘Flash’ plus ‘Ambient’ lighting – IIPVAPI can bring a natural look to the images. Our real estate image editors can seamlessly tackle the issue of flash
                            </Typography.Paragraph>
                            <Button onClick={() => navigate("/services-2")} className='web-primary-btn'>View More</Button>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6} className='homepg-service-col'>
                        <div className='homepg-service-box'>
                            <h2 className='header-h2'>HDR Blending</h2>
                            <Divider />
                            <Typography.Paragraph ellipsis={{ rows: 3 }} className='homepg-service-text'>
                                The process of High Dynamic Range Blending or HDR image editing involves blending of two or more images that vary in temperature, quality, and brightness and creating unimpaired images. This service is very useful for Professional Photographers who want to remove the image flaws that might occur during the shoot based on the lighting and temparature.
                            </Typography.Paragraph>
                            <Button onClick={() => navigate("/services-3")} className='web-primary-btn'>View More</Button>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6} className='homepg-service-col'>
                        <div className='homepg-service-box'>
                            <h2 className='header-h2'>Sky Replacement</h2>
                            <Divider />
                            <Typography.Paragraph ellipsis={{ rows: 3 }} className='homepg-service-text'>
                                If you are a real estate seller then which kind of photos would you like to show to your client or buyer. Would you like to show ‘without sky or grass’ or ‘with sky and grass’? As we believe your answer would be ‘with sky and grass.’- Sometimes due to bad weather conditions, dull or cloudy sky, lack of maintenance, negligence, changing season etc, the grass dies. Hence, in that case you may need this service to make your picture presentable.
                            </Typography.Paragraph>
                            <Button onClick={() => navigate("/services-4")} className='web-primary-btn'>View More</Button>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6} className='homepg-service-col'>
                        <div className='homepg-service-box'>
                            <h2 className='header-h2'>Lawn Replacement</h2>
                            <Divider />
                            <Typography.Paragraph ellipsis={{ rows: 3 }} className='homepg-service-text'>
                                If you are a real estate seller then which kind of photos would you like to show to your client or buyer. Would you like to show ‘without sky or grass’ or ‘with sky and grass’? As we believe your answer would be ‘with sky and grass.’- Sometimes due to bad weather conditions, dull or cloudy sky, lack of maintenance, negligence, changing season etc, the grass dies. Hence, in that case you may need this service to make your picture presentable.
                            </Typography.Paragraph>
                            <Button onClick={() => navigate("/services-5")} className='web-primary-btn'>View More</Button>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6} className='homepg-service-col'>
                        <div className='homepg-service-box'>
                            <h2 className='header-h2'>360 Panorama</h2>
                            <Divider />
                            <Typography.Paragraph ellipsis={{ rows: 3 }} className='homepg-service-text'>
                                Dynamics are more powerful to draw a viewer’s attention than the statics. Panoramic view of an object encourages a viewer to become live with it, taking him or her as an integral part and definitely a significant player of the dynamic frame. Sometimes, it can create an
                            </Typography.Paragraph>
                            <Button onClick={() => navigate("/services-6")} className='web-primary-btn'>View More</Button>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6} className='homepg-service-col'>
                        <div className='homepg-service-box'>
                            <h2 className='header-h2'>Deculter</h2>
                            <Divider />
                            <Typography.Paragraph ellipsis={{ rows: 3 }} className='homepg-service-text'>
                                Our experienced and professional digital de-cluttering editors can remove, general clutter, Rubbish, pool debris, vehicles, remove signs posters, change water color, wall pictures and everything you want to take away from the image. We have a team of highly accomplished photo editors who can handle large range of images effectively, and deliver terrific results. The results provided by us are of stunning quality and clarity. Some of our major services include.
                            </Typography.Paragraph>
                            <Button onClick={() => navigate("/services-7")} className='web-primary-btn'>View More</Button>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6} className='homepg-service-col'>
                        <div className='homepg-service-box'>
                            <h2 className='header-h2'>Virtual Staging</h2>
                            <Divider />
                            <Typography.Paragraph ellipsis={{ rows: 3 }} className='homepg-service-text'>
                            Virtual Staging has emerged as a formidable technological advancement in Home Staging techniques. It's a cost-effective alternative to traditional staging, however, it can also be a great supplement to the traditional staging of vacant homes. As the pioneers of the Virtual Staging movement, the founders and professionals of Virtually Staging Properties have developed an exclusive, proprietary virtual staging process.
                            </Typography.Paragraph>
                            <Button onClick={() => navigate("/services-8")} className='web-primary-btn'>View More</Button>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6} className='homepg-service-col'>
                        <div className='homepg-service-box'>
                            <h2 className='header-h2'>Virtual Twilight</h2>
                            <Divider />
                            <Typography.Paragraph ellipsis={{ rows: 3 }} className='homepg-service-text'>
                            Virtual Twilights give the illusion of beautiful Twilight photography without the hassle of scheduling a separate evening appointment. If the weather isn’t cooperating for a Twilight shoot or you only need a few photos featuring a stunning sunset in the background, this service is a great way to easily transform existing daytime photography into a Virtual Twilight.
                            </Typography.Paragraph>
                            <Button onClick={() => navigate("/services-9")} className='web-primary-btn'>View More</Button>
                        </div>
                    </Col>
                    {/*<Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6} className='homepg-service-col'>
                        <div className='homepg-service-box'>
                            <h2 className='header-h2'>Web Design</h2>
                            <Divider />
                            <Typography.Paragraph ellipsis={{ rows: 3 }} className='homepg-service-text'>
                                Web design encompasses many different skills and disciplines in the production and maintenance of websites. The different areas of web design include web graphic design; user interface design; authoring, including standardised code and proprietary software; user experience.
                            </Typography.Paragraph>
                            <Button className='web-primary-btn'>View More</Button>
                        </div>
                    </Col>*/}
                </Row>
            </div>
            {/***************************************************************CLIENT SAYS */}
            <div className='web-sections-light homepg-client-section'>
                <h1 className='header-h1'>Customer Review</h1>
                <Carousel autoplay className='homepg-client-carousel'>
                    <div>
                        <h3 className='header-h3'>John Schiavone, Jr.</h3>
                        <p className='homepg-client-text'><i>I cannot express it more clearly than to say Picxsquare is nothing less than INCREDIBLE. They truly deliver top-notch work, always high quality in everything they do and they have impeccable customer service - always accomodating and quick to reply to any questions or needs I have.</i></p>
                    </div>
                    <div>
                        <h3 className='header-h3'>John Schiavone, Jr.</h3>
                        <p className='homepg-client-text'><i>I cannot express it more clearly than to say Picxsquare is nothing less than INCREDIBLE. They truly deliver top-notch work, always high quality in everything they do and they have impeccable customer service - always accomodating and quick to reply to any questions or needs I have.</i></p>
                    </div>
                </Carousel>
            </div>
            {/***************************************************************HOW DOES IT WORKS */}
            <div className='web-sections-dark' id='hiw'>
                <h1 className='header-h1'>How Does Picxsquare Works</h1>
                <Row gutter={[16, 16]} className='homepg-hiw-row'>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={6}>
                        <div className='homepg-hiw-box'>
                            <span className='homepg-hiw-ico'><img src={Work_1} className="homepg-hiw-img" alt="Picxsquare" /></span>
                            <h2 className='homepg-hiw-h2'>Upload Images</h2>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={6}>
                        <div className='homepg-hiw-box'>
                            <span className='homepg-hiw-ico'><img src={Work_2} className="homepg-hiw-img" alt="Picxsquare" /></span>
                            <h2 className='homepg-hiw-h2'>We Edit</h2>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={6}>
                        <div className='homepg-hiw-box'>
                            <span className='homepg-hiw-ico'><img src={Work_3} className="homepg-hiw-img" alt="Picxsquare" /></span>
                            <h2 className='homepg-hiw-h2'>Quality Check</h2>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={6}>
                        <div className='homepg-hiw-box'>
                            <span className='homepg-hiw-ico'><img src={Work_4} className="homepg-hiw-img" alt="Picxsquare" /></span>
                            <h2 className='homepg-hiw-h2'>Get Back</h2>
                        </div>
                    </Col>
                </Row>
            </div>
            {/***************************************************************LETS WORK TOGETHER */}
            {/* //ToDo in future */}
            {/***************************************************************FOOTER */}
            <Footer />
        </Layout>
    )
}

export default HomePage;
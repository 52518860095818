import React, { useState } from 'react';
import { Layout, Row, Col, Divider, Button, Form, Input, InputNumber, Select, Alert } from 'antd';
import Navbar from '../../layouts/navbar/navbar';
import Footer from '../../layouts/footer/footer';
import Icon1 from '../../assets/images/icons/career_1.png';
import Icon2 from '../../assets/images/icons/career_2.png';
import Icon3 from '../../assets/images/icons/career_3.png';
import { useStore } from "../../use-stores";
import { SERVER_BOT } from "../../utils/constant";
import './careers.css';

const CareersPage: React.FC = () => {
    const { generalStore } = useStore();
    const [jobFormState, setJobFormState] = useState<boolean>(true);
    const [selectedJob, setSelectedJob] = useState<string>('image_editor');
    const [basicFormState, setBasicFormState] = useState<boolean>(false);
    const [alertSuccess, setAlertSuccess] = useState<boolean>(false);
    const [alertFailed, setAlertFailed] = useState<boolean>(false);
    const [form] = Form.useForm();

    const openForm = (job: string) => {
        setSelectedJob(job)
        setJobFormState(false)
    }
    const letsRequestForJob = async (value: any) => {
        try {
            setBasicFormState(true);
            let _post: any = {
                first_name: value.first_name,
                last_name: value.last_name,
                email: value.email,
                mobile: value.mobile,
                message: value.message,
                experience: value.experience,
                designation: value.designation,
                qualification: value.qualification,
                created_by: SERVER_BOT,
                updated_by: SERVER_BOT
            }
            await generalStore.doJobRequest(_post).then((_d: any) => {
                if (_d && _d.status === "error") {
                    setAlertFailed(true); setAlertSuccess(false); form.resetFields();
                } else { setAlertSuccess(true); setAlertFailed(false); form.resetFields(); }
            }).finally(() => {
                setBasicFormState(false)
            })
        } catch (e) {
            console.error(e)
        }
    }
    const onFailure = (errorInfo: any) => {
        console.error(errorInfo)
    }

    return (
        <Layout className='web-layout'>
            <Navbar />
            {/*********************************************************************PAGE HEADER */}
            <div className='wep-page-header'>
                <h1>Careers__</h1>
            </div>
            {/*********************************************************************PAGE */}
            <div className='web-page-container'>
                <div className='web-box careerpg-box'>
                    <h2 className='header-h2'>Currently looking for </h2>
                    <Row gutter={[20, 20]} className='careerpg-row'>
                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={4}>
                            <div className='careerpg-job-box'>
                                <img src={Icon2} alt="Picxsquare" className='careerpg-job-img' />
                                <p className='careerpg-job-p'>Image Editing</p>
                                <Divider className='careerpg-job-line' />
                                <Button className='web-btn-white' onClick={() => openForm('image_editor')}>Apply</Button>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={4}>
                            <div className='careerpg-job-box'>
                                <img src={Icon1} alt="Picxsquare" className='careerpg-job-img' />
                                <p className='careerpg-job-p'>Customer Support</p>
                                <Divider className='careerpg-job-line' />
                                <Button className='web-btn-white' onClick={() => openForm('customer_service')}>Apply</Button>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={4}>
                            <div className='careerpg-job-box'>
                                <img src={Icon3} alt="Picxsquare" className='careerpg-job-img' />
                                <p className='careerpg-job-p'>Video Editing</p>
                                <Divider className='careerpg-job-line' />
                                <Button className='web-btn-white' onClick={() => openForm('video_editor')}>Apply</Button>
                            </div>
                        </Col>
                    </Row>
                </div>
                <br />
                <Form form={form} onFinish={letsRequestForJob} disabled={basicFormState} onFinishFailed={onFailure} layout="vertical" autoComplete="off" hidden={jobFormState} className='careerpg-form' >
                    <div className='web-box'>
                        <h2 className='header-h2'>Apply Here </h2>
                        {alertSuccess &&
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={16} xl={12} xxl={12}><Alert
                                    message="Success! Picxsquare person will contact you within 24hrs. Thank you."
                                    type="success"
                                    showIcon style={{ marginBottom: "15px" }}
                                /></Col></Row>}
                        {alertFailed && <Row>
                            <Col xs={24} sm={24} md={24} lg={16} xl={12} xxl={12}><Alert
                                message="Failed! Something went wrong. Try again."
                                type="error"
                                showIcon style={{ marginBottom: "15px" }}
                            /></Col></Row>}
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={16} xl={12} xxl={12}>
                                <Form.Item name="first_name" label={<label className="web-form-label">First Name</label>} rules={[{ required: true, message: "Please input your Full Name" }]}>
                                    <Input className="web-form-input" />
                                </Form.Item>
                                <Form.Item name="last_name" label={<label className="web-form-label">Last Name</label>} rules={[{ required: false }]}>
                                    <Input className="web-form-input" />
                                </Form.Item>
                                <Form.Item name="mobile" label={<label className="web-form-label">Mobile</label>} rules={[{ required: true, message: "Please input your mobile number" }]}>
                                    <Input className="web-form-input" />
                                </Form.Item>
                                <Form.Item name="email" label={<label className="web-form-label">Email</label>} rules={[{ required: true, type: "email", message: "Please input your Email" }]}>
                                    <Input className="web-form-input" />
                                </Form.Item>
                                <Form.Item name="qualification" label={<label className="web-form-label">Qualification</label>} rules={[{ required: true, message: "Please input your Qualification" }]}>
                                    <Input className="web-form-input" />
                                </Form.Item>
                                <Form.Item name="designation" initialValue={{ selectedJob }} label={<label className="web-form-label">Designation</label>} rules={[{ required: true, message: "Please input your Designation" }]}>
                                    <Select showSearch optionFilterProp="children">
                                        <Select.Option value='image_editor'>Image Editor</Select.Option>
                                        <Select.Option value='customer_service'>Customer Service</Select.Option>
                                        <Select.Option value='video_editor'>Video Editor</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item name="experience" label={<label className="web-form-label">Experience</label>} rules={[{ required: true, type: 'number', max: 10, message: "Please input your Experience" }]}>
                                    <InputNumber className="web-form-input" />
                                </Form.Item>
                                <Form.Item name="message" label={<label className="web-form-label">Do you want to say something?</label>} rules={[{ required: true, message: "Please input your Message" }]}>
                                    <Input.TextArea className="web-form-textarea" rows={4} />
                                </Form.Item>
                                <Button htmlType="submit" size="large" className="web-primary-btn careerpg-submit">Send</Button>
                                <Button onClick={() => setJobFormState(true)} size="large" className="web-secondary-btn">Cancel</Button>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
            {/*********************************************************************MODEL */}

            {/*********************************************************************FOOTER */}
            <Footer />
        </Layout>
    )
}

export default CareersPage;
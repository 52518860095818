import React from 'react';
import { Layout, Row, Col } from 'antd';
import Navbar from '../../../layouts/navbar/navbar';
import Footer from 'src/layouts/footer/footer';
import A_1 from '../../../assets/images/services/staging/a-1.jpg';
import B_1 from '../../../assets/images/services/staging/b-1.jpg';
import A_2 from '../../../assets/images/services/staging/a-2.jpg';
import B_2 from '../../../assets/images/services/staging/b-2.jpg';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';
import '.././services.css';

const ServicesPageSTAGING: React.FC = () => {
    const B_1_IMAGE = { imageUrl: B_1 };
    const A_1_IMAGE = { imageUrl: A_1 };
    const B_2_IMAGE = { imageUrl: B_2 };
    const A_2_IMAGE = { imageUrl: A_2 };

    return (
        <Layout className='web-layout'>
            <Navbar />
            {/*********************************************************************PAGE HEADER */}
            <div className='wep-page-header'>
                <h1>Virtual Staging</h1>
            </div>
            {/*********************************************************************PAGE */}
            <div className='web-page-container'>
                <Row gutter={[12, 12]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <div className='web-box'>
                            <div className='serpg-box'>
                                <p className='serpg-sky-p'>Virtual Staging has emerged as a formidable technological advancement in Home Staging techniques. It's a cost-effective alternative to traditional staging, however, it can also be a great supplement to the traditional staging of vacant homes. As the pioneers of the Virtual Staging movement, the founders and professionals of Virtually Staging Properties have developed an exclusive, proprietary virtual staging process. Our process allows us to use current furnishings and decor to create virtually staged photos of vacant homes, producing exceptionally photorealistic staged photos for clients all around the country.</p>
                                <p className='serpg-sky-p'>We edit the photos of the empty property, then share an amazing portfolio of rooms and styles. Once you’ve selected your options, our talented group of 3D CGI artists use the latest and greatest Hollywood techniques and software to magically furnish your space!</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            {/*********************************************************************SAMPLES */}
            <div className='web-page-container'>
                <Row gutter={[12, 12]} className='serpg-ba'>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                        <div className='web-box'>
                            <ReactBeforeSliderComponent firstImage={B_1_IMAGE} secondImage={A_1_IMAGE} />
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                        <div className='web-box'>
                            <ReactBeforeSliderComponent firstImage={B_2_IMAGE} secondImage={A_2_IMAGE} />
                        </div>
                    </Col>
                </Row>
            </div>
            {/*********************************************************************FOOTER */}
            <Footer />
        </Layout>
    )
}

export default ServicesPageSTAGING;
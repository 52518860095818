import React, { useState } from 'react';
import { Layout, Row, Col, Modal } from 'antd';
import Navbar from '../../layouts/navbar/navbar';
import Footer from '../../layouts/footer/footer';
import Image1 from '../../assets/images/gallery/1.jpg';
import Image2 from '../../assets/images/gallery/2.jpg';
import Image3 from '../../assets/images/gallery/3.jpg';
import Image4 from '../../assets/images/gallery/4.jpg';
import Image5 from '../../assets/images/gallery/5.jpg';
import Image6 from '../../assets/images/gallery/6.jpg';
import Image7 from '../../assets/images/gallery/7.jpg';
import Image8 from '../../assets/images/gallery/8.jpg';
import Image9 from '../../assets/images/gallery/9.jpg';
import Image10 from '../../assets/images/gallery/10.jpg';
import Image11 from '../../assets/images/gallery/11.jpg';
import Image12 from '../../assets/images/gallery/12.jpg';
import Image13 from '../../assets/images/gallery/13.jpg';
import Image14 from '../../assets/images/gallery/14.jpg';
import Image15 from '../../assets/images/gallery/15.jpg';
import Image16 from '../../assets/images/gallery/16.jpg';
import Image17 from '../../assets/images/gallery/17.jpg';
import Image18 from '../../assets/images/gallery/18.jpg';
import Image19 from '../../assets/images/gallery/19.jpg';
import Image20 from '../../assets/images/gallery/20.jpg';
import Image21 from '../../assets/images/gallery/21.jpg';
import Image22 from '../../assets/images/gallery/22.jpg';
import Image23 from '../../assets/images/gallery/23.jpg';
import Image24 from '../../assets/images/gallery/24.jpg';
import Image25 from '../../assets/images/gallery/25.jpg';
import Image26 from '../../assets/images/gallery/26.jpg';
import Image27 from '../../assets/images/gallery/27.jpg';
import './gallery.css';

const GalleryPage: React.FC = () => {
    const [modelOpen, setModelOpen] = useState<boolean>(false);
    const [currentImg, setCurrentImg] = useState<string>(Image1);
    const letsZoomImage = (image: string) => {
        setCurrentImg(image)
        setModelOpen(true)
    }
    return (
        <Layout className='web-layout'>
            <Navbar />
            {/*********************************************************************PAGE HEADER */}
            <div className='wep-page-header'>
                <h1>Gallery__</h1>
            </div>
            {/*********************************************************************PAGE */}
            <div className='web-page-container'>
                <div className='web-box'>
                    <Row gutter={[6, 3]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}><img src={Image1} alt="Picxsquare" onClick={() => { letsZoomImage(Image1) }} className='portpg-img' /></Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}><img src={Image2} alt="Picxsquare" onClick={() => { letsZoomImage(Image2) }} className='portpg-img' /></Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}><img src={Image3} alt="Picxsquare" onClick={() => { letsZoomImage(Image3) }} className='portpg-img' /></Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}><img src={Image4} alt="Picxsquare" onClick={() => { letsZoomImage(Image4) }} className='portpg-img' /></Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}><img src={Image5} alt="Picxsquare" onClick={() => { letsZoomImage(Image5) }} className='portpg-img' /></Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}><img src={Image6} alt="Picxsquare" onClick={() => { letsZoomImage(Image6) }} className='portpg-img' /></Col>

                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}><img src={Image7} alt="Picxsquare" onClick={() => { letsZoomImage(Image7) }} className='portpg-img' /></Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}><img src={Image8} alt="Picxsquare" onClick={() => { letsZoomImage(Image8) }} className='portpg-img' /></Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}><img src={Image9} alt="Picxsquare" onClick={() => { letsZoomImage(Image9) }} className='portpg-img' /></Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}><img src={Image10} alt="Picxsquare" onClick={() => { letsZoomImage(Image10) }} className='portpg-img' /></Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}><img src={Image11} alt="Picxsquare" onClick={() => { letsZoomImage(Image11) }} className='portpg-img' /></Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}><img src={Image12} alt="Picxsquare" onClick={() => { letsZoomImage(Image12) }} className='portpg-img' /></Col>

                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}><img src={Image13} alt="Picxsquare" onClick={() => { letsZoomImage(Image13) }} className='portpg-img' /></Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}><img src={Image14} alt="Picxsquare" onClick={() => { letsZoomImage(Image14) }} className='portpg-img' /></Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}><img src={Image15} alt="Picxsquare" onClick={() => { letsZoomImage(Image15) }} className='portpg-img' /></Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}><img src={Image16} alt="Picxsquare" onClick={() => { letsZoomImage(Image16) }} className='portpg-img' /></Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}><img src={Image17} alt="Picxsquare" onClick={() => { letsZoomImage(Image17) }} className='portpg-img' /></Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}><img src={Image18} alt="Picxsquare" onClick={() => { letsZoomImage(Image18) }} className='portpg-img' /></Col>

                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}><img src={Image19} alt="Picxsquare" onClick={() => { letsZoomImage(Image19) }} className='portpg-img' /></Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}><img src={Image20} alt="Picxsquare" onClick={() => { letsZoomImage(Image20) }} className='portpg-img' /></Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}><img src={Image21} alt="Picxsquare" onClick={() => { letsZoomImage(Image21) }} className='portpg-img' /></Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}><img src={Image22} alt="Picxsquare" onClick={() => { letsZoomImage(Image22) }} className='portpg-img' /></Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}><img src={Image23} alt="Picxsquare" onClick={() => { letsZoomImage(Image23) }} className='portpg-img' /></Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}><img src={Image24} alt="Picxsquare" onClick={() => { letsZoomImage(Image24) }} className='portpg-img' /></Col>

                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}><img src={Image25} alt="Picxsquare" onClick={() => { letsZoomImage(Image25) }} className='portpg-img' /></Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}><img src={Image26} alt="Picxsquare" onClick={() => { letsZoomImage(Image26) }} className='portpg-img' /></Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}><img src={Image27} alt="Picxsquare" onClick={() => { letsZoomImage(Image27) }} className='portpg-img' /></Col>
                    </Row>
                </div>
            </div>
            {/*********************************************************************MODEL */}
            <Modal centered open={modelOpen} width={'75vw'} footer={false} onCancel={() => setModelOpen(false)} className="portpg-img-model">
                <img src={currentImg} alt="Picxsquare" className='portpg-img' />
            </Modal>
            {/*********************************************************************FOOTER */}
            <Footer />
        </Layout>
    )
}

export default GalleryPage;